<template>
  <div>
    <ManageBanners_2 />
  </div>
</template>
<script>
import ManageBanners_2 from "@/components/Banners_2/ManageBanners_2";
export default {
  components: {
    ManageBanners_2,
  },
  created() {
  },
};
</script>
